export const NON_TMV_DEALERS = [
  '59884',
  '1808712',
  '1808517',
  '64669',
  '1815189',
  '28821',
  '763696',
  '28807',
  '28816',
  '28817',
  '28350',
  '739982',
  '825568',
  '825569',
  '65078',
  '1866024',
  '1866035',
  '838562',
  '827054',
  '827055',
  '827056',
  '827057',
  '1865601',
  '1826890',
  '838985',
  '838987',
  '838989',
  '838991',
  '1865593',
  '824713',
  '63885',
  '897758',
  '66879',
  '26368',
  '28431',
  '53160',
  '1868945',
  '26850',
  '716711',
  '35200',
  '35160',
  '28687',
  '27261',
  '50751',
  '1879084',
  '26712',
  '27667',
  '737308',
  '27177',
  '1924884',
  '51348',
  '28393',
  '27736',
  '28597',
  '66172',
  '45769',
];
